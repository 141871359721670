(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

var base = {
    findParentNode: function findParentNode(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls)) {}
        return el;
    },
    hamburger: function hamburger() {
        var hamburger = document.querySelector(".hamburger"),
            mobileNav = document.querySelector(".mobile-nav");
        hamburger.addEventListener("click", function (event) {
            document.getElementById("menu-toggle").classList.toggle("open");
            document.querySelector("body").classList.toggle("is-overflow");
            mobileNav.classList.toggle("mobile-nav--opened");
        });
    },
    init: function init() {
        this.hamburger();
    }
};

window.base = base;

module.exports = base;

},{}],2:[function(require,module,exports){
"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Home = function () {
    function Home(pagename) {
        _classCallCheck(this, Home);

        this.pagename = pagename;
    }

    _createClass(Home, [{
        key: "printName",
        value: function printName() {
            console.log("This is " + this.pagename);
        }
    }, {
        key: "init",
        value: function init() {
            this.printName();
        }
    }]);

    return Home;
}();

var home = new Home("homepage");

module.exports = home;

},{}],3:[function(require,module,exports){
"use strict";

// const _ = require("lodash");
// const axios = require("axios");

window.onload = function () {

	var pageName = document.querySelector("body").getAttribute("id");
	var base = require("./global/base");
	base;
	base.init();

	var modulesList = {
		"home": require("./helper/home")
	};

	modulesList[pageName].init();
};

},{"./global/base":1,"./helper/home":2}]},{},[3]);
